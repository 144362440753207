.points-section{
    padding-top: 188px;
    padding-bottom: 142px;
    .main-content{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        margin-bottom: 157px;
        h6{
            color: #FFF;
            text-align: center;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            margin-top: 50px;
            margin-bottom: 25px;
        }
        h4{
            color: #FFF;
            font-size: 72px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%; 
            text-transform: uppercase;
            margin-bottom: 50px;
        }
        p{
            color: #FFF;
            text-align: center;
            font-size: 26px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            margin-bottom: 25px;
        }
        h5{
            color: #FFF;
            font-size: 50px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%; 
        }
    }
    .bottom-btn{
        margin-top: 40px;
        button{
            border: 1px solid #FFF;
            background: #000;
            box-shadow: 2px 3px 0px 0px #FFF;
            padding: 32px 61px;
            color: #FFF;
            text-align: center;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; 
            text-transform: uppercase;
            width: 90%;
            margin: 0 auto;
            display: block;
        }
    }
}

@media (max-width:600px){
    .points-section .main-content img{
        width: 150px;
        height: 150px;
    }
    .points-section .main-content h6{
        font-size: 24px;
    }
    .points-section .main-content h4{
        font-size: 40px;
    }
    .points-section .main-content p{
        font-size: 18px;
    }
    .points-section .main-content h5{
        font-size: 30px;
    }
    .points-section .bottom-btn button{
        padding: 24px;
        font-size: 24px;
    }
    .points-section .main-content{
        margin-bottom: 80px;
    }
}