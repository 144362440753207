.header-section {
    border: 1px solid #3A3A3A;
    background: rgba(0, 0, 0, 0.70);
    padding: 15px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 200px;
}

.login-section {
    padding-bottom: 200px;
    .parent {
        border: 1px solid #2C2C2C;
        background: #000;
        padding: 20px;
        max-width: 518px;
        width: 100%;
        margin: 0 auto;

        .upper-heading {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #2C2C2C;
            background: #000;
            padding: 15px;
            margin-bottom: 35px;

            h6 {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                text-transform: uppercase;
            }

        }

        .option-field {
            margin-bottom: 35px;

            p {
                color: #767676;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                // text-transform: uppercase;
                margin-bottom: 16px;
            }

            input {
                border: 1px solid #2C2C2C;
                background: #000;
                padding: 20px;
                color: #fff;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                // text-transform: uppercase;
                width: 100%;

                &::placeholder {
                    color: #767676;
                }
            }
        }

        .signin-btn {
            border: 1px solid #FFF;
            background: #000;
            box-shadow: 2px 3px 0px 0px #FFF;
            padding: 10px 61px;
            width: 100%;
            color: #FFF;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            height: 60px;
        }
    }
}