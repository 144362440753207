.congrats-section{
    padding-top: 88px;
    padding-bottom: 108px;
    .main-img{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
    }
    .bottom-content{
        h5{
            color: #FFF;
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%; 
            text-transform: uppercase;
            margin-bottom: 25px;
            text-align: center;
        }
        p{
            color: #FFF;
            text-align: center;
            font-size: 35px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; 
            margin-bottom: 70px;
        }
        .btn-view{
            border: 1px solid #FFF;
            background: #000;
            box-shadow: 2px 3px 0px 0px #FFF;
            padding: 32px 61px;
            color: #FFF;
            text-align: center;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .bottom-logo{
        margin-top: 74px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        h4{
            color: #FFF;
            text-align: center;
            font-size: 25px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; 
            text-transform: uppercase;
        }
    }
}

@media (max-width:600px){
    .congrats-section .bottom-content h5{
        font-size: 28px;
    }
    .congrats-section .bottom-content p{
        font-size: 22px;
    }
    .congrats-section .bottom-content .btn-view{
        font-size: 25px;
        padding: 20px;
    }
    .congrats-section{
        padding-top: 30px;
    }
}