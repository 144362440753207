.how-section {
    padding-top: 121px;
    padding-bottom: 70px;

    .main-heading {
        margin-bottom: 30px;

        h4 {
            color: #FFF;
            text-align: center;
            font-size: 33px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
        }
    }

    .bottom-content {
        background: #070707;
        padding: 20px;
        max-height: 80vh;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 5px;
        }


        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px #131313;
            border-radius: 10px;
        }


        &::-webkit-scrollbar-thumb {
            background: #fff;
            border-radius: 10px;
        }


        &::-webkit-scrollbar-thumb:hover {
            background: #808080;
        }

        .inside-parent {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;

            .single-item {
                border: 1px solid #2E2E2E;
                background: #030303;
                padding: 19px 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 20px;
                cursor: pointer;

                .left-side {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 20px;

                    img {
                        flex-shrink: 0;
                    }

                    h6 {
                        color: #FFF;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 130%;
                    }
                }

            }
        }
    }
    .bottom-btn{
        margin-top: 40px;
        button{
            border: 1px solid #FFF;
            background: #000;
            box-shadow: 2px 3px 0px 0px #FFF;
            padding: 32px 61px;
            color: #FFF;
            text-align: center;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; 
            text-transform: uppercase;
            width: 90%;
            margin: 0 auto;
            display: block;
        }
    }
}


@media (max-width:1024px){
    .how-section .custom-container{
        max-width: 100%;
    }
}

@media (max-width:600px){
    .how-section .main-heading h4{
        font-size: 24px;
    }
    .how-section .bottom-content .inside-parent{
        grid-template-columns: 1fr;
    }
    .how-section .bottom-content .inside-parent .single-item .left-side h6{
        font-size: 14px;
    }
    .how-section .bottom-content .inside-parent .single-item{
        padding: 15px;
    }
    .how-section .bottom-btn button{
        padding: 24px;
        font-size: 24px;
    }
}