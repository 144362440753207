.main-navbar {
    // border: 1px solid #3A3A3A;
    // background: rgba(0, 0, 0, 0.70);
    height: 69px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 999;
    margin-top: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .navbar {
        justify-content: space-between;
        border: 1px solid #3A3A3A;
        background: rgba(0, 0, 0, 0.70);
        padding: 15px 30px;

    }

    .navbar-brand {
        margin: 0;
    }

    .navbar-nav {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 595px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;

        .nav-item {
            .nav-link {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;
                color: #9A9A9A;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: -0.012px;
                text-transform: uppercase;
                padding: 0;

                &::after {
                    display: none;
                }
            }
        }
    }
}