@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');


html {
  scroll-behavior: smooth;
  --wcm-z-index: 999999 !important;
}

body {
  font-family: "Space Mono", monospace;
  background: #000;
  height: 100vh;
}


.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}


.grey {
  color: #888 !important;
}



.ptb20 {
  padding: 20px 0;
}




.ptb60 {
  padding: 60px 0;
}






.cursor-pointer {
  cursor: pointer;
}

.purple {
  color: #6c01c3;
}

.yellow {
  color: #ffc13d;
}

.border-img {
  border-radius: 10px;
}

.common {
  color: #ff0083;
}

.green {
  color: #6dff39;
}

.red {
  color: #f41e5e;
}

.white {
  color: #fff !important;
}

.blue {
  color: #133572;
}

.navbar-toggle {
  background-color: #333;

  .icon-bar {
    background-color: #eee;
  }
}


@media only screen and (max-width: 600px) {
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    outline: none;

    &:focus {
      outline: none;
    }
  }

  .main-footer {
    text-align: center;

    .text-right {
      text-align: center !important;
    }
  }

  h1 {
    font-size: 2.5rem !important;
  }

  .ptb {
    padding: 30px 0;
  }

  .xs-0 {
    padding: 0px;
  }

  .main-mint,
  .main-banner {
    .inner-content {
      .modal-content {
        .for-padding {
          padding: 0 0px !important;
        }
      }
    }
  }

  .main-give {
    .right-side {
      margin-top: 30px !important;

      h5 {
        font-weight: 500;
      }

      li {
        margin-right: 2px !important;
      }
    }
  }
}

// 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {

  h1 {
    font-size: 3rem !important;
  }

  .ptb {
    padding: 20px 0;
  }
}



iframe {
  position: unset !important;
  top: 0px !important;
  left: 0px !important;
  width: 0px !important;
  height: 0px !important;
}

button {
  cursor: pointer;
}

@media (max-width: 600px) {
  h2 {
    font-size: 30px !important;
  }

  .col-md-6 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-8 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-4 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

button:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

.form-control:focus {
  box-shadow: unset;
}


@media(max-width:600px) {
  .modal.show .modal-dialog {
    max-width: 100% !important;
  }
}

button {
  cursor: pointer;
}

a {
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea:focus-visible {
  outline: none !important;

}

button,
input,
optgroup,
select,
textarea:focus {
  outline: none !important;

}

button.close {
  opacity: 1;
}


.btn-launch {
  border: 1px solid #FFF;
  background: #000;
  box-shadow: 2px 2px 0px 0px #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  h6 {
    padding: 10px 12px;
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    border-right: 1px solid #FFF;
    background: #000;
  }

  svg {
    width: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


.custom-container {
  max-width: 814px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}


.custom-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;

  .form-group {
    display: block;
  }

  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .form-group label,
  label {
    position: relative;
    cursor: pointer;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: capitalize;

    a {
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      text-decoration: underline !important;
      text-transform: capitalize;
    }
  }

  .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 7px;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }

  .form-group input:checked+label:before {
    content: '';
    background-color: #FFF;
  }

  .form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}


.common-modal {
  .modal-dialog {
    max-width: 741px;
  }

  .modal-content {
    border: 1px solid #727272;
    background: #000;
    padding: 20px 20px 50px 20px;
  }

  .modal-header {
    border: 1px solid #3A3A3A;
    background: rgba(0, 0, 0, 0.70);
    border-radius: 0;

    .modal-title {
      color: #FFF;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      text-transform: uppercase;
    }

    .btn-close {
      background: url("../src/assets/close-btn-modal.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 1;
      box-shadow: none;
      border-radius: 0;
    }
  }

  .modal-body {
    padding: 0;
    padding-top: 35px;

    .inside-content {
      .option-field {
        margin-bottom: 26px;
        position: relative;

        p {
          color: #767676;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: uppercase;
          margin-bottom: 16px;
        }

        input {
          border: 1px solid #2C2C2C;
          background: #000;
          padding: 20px;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: 100%;

          &::placeholder {
            color: #767676;
          }
        }

        .pick-icons-container {
          
          width: 100%;
          padding: 15px 0px;
          border: 1px solid #2C2C2C;
        }

        .pick-icons {

          .single-pick {
            padding: 19px 10px 20px 19px;
            position: relative;
            flex-shrink: 0;
            cursor: pointer;


            img {
              width: 75px;
              height: 75px;
              pointer-events: none;
            }

            .custom-checkbox {
              position: absolute;
              top: 5px;
              left: 5px;
              opacity: 0;

            }

          }

          .single-pick:has(input:checked) {
            background: #0B0B0B;
            transform: scale(1.1);
          }

          .single-pick:has(input:checked) .custom-checkbox {
            opacity: 1;
          }
        }

        .wallet-connect {
          border: 1px solid #2C2C2C;
          background: #121212;
          padding: 30px 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          h6 {
            color: #fff;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 13px 0;
            &.connected{
              color: #01E2AC !important;
            }
          }

          p {
            color: #FFF;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            text-transform: capitalize;
          }
        }

        .twice-div{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
          .twice-btn{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 15px;
            button{
              background-color: transparent;
              border: none;
            }
          }
        }

       
      }

      .bottom-btn {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;


        button {
          border: 1px solid #FFF;
          background: #000;
          box-shadow: 2px 3px 0px 0px #FFF;
          padding: 32px 61px;
          color: #FFF;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
          text-transform: uppercase;
          width: 100%;
          margin: 0 auto;
          display: block;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .common-modal .modal-body .inside-content .option-field .pick-icons-container {
    padding: 15px 0;
  }

  .common-modal .modal-header .modal-title {
    font-size: 16px;
  }

  .common-modal .modal-body .inside-content .option-field .pick-icons .single-pick img {
    width: 50px;
    height: 50px;
  }

  .common-modal .modal-body .inside-content .bottom-btn button {
    padding: 24px;
    font-size: 24px;
  }
  
}

.wcm-overlay {
  --wcm-z-index: 999999 !important;
}


.Toastify__progress-bar {
  // display: none;

}

.Toastify__progress-bar--error {
  background: #FF0000 !important;
}

.Toastify__progress-bar--warning {
  background: #FF7600 !important;
}

.Toastify__progress-bar--success {
  background: #8CC63E !important;
}


.Toastify__toast-body>div:last-child {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.Toastify__close-button {
  display: none;
}

.Toastify__zoom-enter {
  display: none !important;
}

.leaderboard-container {
  max-width: 2560px;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  margin: 0 auto;
}


.banner-wrapper-landing{
  .main-navbar .navbar-brand{
    pointer-events: none;
  }
}