.main-banner {
    position: relative;
    z-index: 99;
    padding-top: 135px;
    padding-bottom: 105px;

    .banner-bg {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        z-index: -1;
        pointer-events: none;
        width: 100%;
        max-height: 455px;
        height: 100%;
        object-position: bottom;
    }

    .main-content {
        margin-bottom: 50px;

        h4 {
            color: #FFF;
            text-align: center;
            font-size: 50px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            margin-bottom: 20px;
        }

        p {
            color: #FFF;
            text-align: center;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            margin-bottom: 47px;
        }

        .bottom-twice {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            .single-card {
                border: 1px solid #2E2E2E;
                background: #000;
                padding: 19px 20px;
                width: 100%;

                h6 {
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    text-transform: uppercase;
                    margin-top: 14px;
                }
            }

            span {
                display: flex;
                flex-shrink: 0;
            }
        }
    }

    .leaderboard {
        background: #0B0B0B;
        padding: 30px 10px;
        width: 100%;
        margin-bottom: 50px;

        .main-heading {
            margin-bottom: 25px;

            h4 {
                color: #FFF;
                text-align: center;
                font-size: 31px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: uppercase;
            }
        }

        .parent {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 40px;
            position: relative;

            .blurryeffect {
                // background-color: rgba(57, 55, 55, 0.4);
                // -webkit-backdrop-filter: blur(20px);
                // backdrop-filter: blur(20px);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 9999;

                h6 {
                    color: #FFF;
                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .single-item {
                border: 1px solid #2E2E2E;
                background: #000;
                padding: 20px 30px;
                min-height: 245px;
                height: 100%;

                .main-img {
                    position: relative;

                    .main-emoji {
                        width: 100px;
                        height: 100px;
                        margin: 0 auto;
                        display: block;
                    }

                    .common-center-img {
                        position: absolute;
                        bottom: -20px;
                        left: 50%;
                        transform: translate(-50%, 0);
                    }
                }

                .text {
                    margin-top: 25px;

                    h6 {
                        color: #FFF;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 130%;
                        margin-bottom: 5px;
                        text-align: center;
                    }

                    p {
                        color: #FFF;
                        text-align: center;
                        font-size: 17.045px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 100%;
                    }
                }

            }

            .center-main {
                .main-img {
                    .main-emoji {
                        width: 127px;
                        height: 127px;
                    }
                }
            }

            .transform-minus {
                transform: scale(0.9);
            }
        }
    }

    .bottom-btn {
        margin-top: 40px;

        button {
            border: 1px solid #FFF;
            background: #000;
            box-shadow: 2px 3px 0px 0px #FFF;
            padding: 32px 61px;
            color: #FFF;
            text-align: center;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            width: 90%;
            margin: 0 auto;
            display: block;
        }
    }

}


// .after-banner {
//     padding-bottom: 80px;
//     padding-top: 156px;


//     .leaderboard {
//         background-color: transparent;
//         padding: 0;
//     }

//     .leaderboard-data {
//         background: #070707;
//         padding: 20px;
//         display: flex;
//         flex-direction: column;
//         gap: 10px;
//         max-height: 686px;
//         overflow-y: auto;

//         &::-webkit-scrollbar {
//             width: 5px;
//         }


//         &::-webkit-scrollbar-track {
//             box-shadow: inset 0 0 5px #131313;
//             border-radius: 10px;
//         }


//         &::-webkit-scrollbar-thumb {
//             background: #fff;
//             border-radius: 10px;
//         }


//         &::-webkit-scrollbar-thumb:hover {
//             background: #808080;
//         }

//         .single-leader {
//             border: 1px solid rgba(255, 255, 255, 0.09);
//             background: #070707;
//             padding: 13px 20px;
//             display: flex;
//             justify-content: space-between;
//             align-items: center;

//             .left-side {
//                 display: flex;
//                 justify-content: flex-start;
//                 align-items: center;
//                 gap: 20px;

//                 p {
//                     color: #FFF;
//                     font-size: 16px;
//                     font-style: normal;
//                     font-weight: 600;
//                     line-height: 100%;
//                 }

//                 .twice {
//                     display: flex;
//                     justify-content: flex-start;
//                     align-items: center;
//                     gap: 10px;

//                     h6 {
//                         color: #FFF;
//                         font-size: 16px;
//                         font-style: normal;
//                         font-weight: 600;
//                         line-height: 130%;
//                         max-width: 300px;
//                     }

//                     img {
//                         flex-shrink: 0;
//                     }
//                 }
//             }

//             .right-side {
//                 h6 {
//                     color: #FFF;
//                     font-size: 16px;
//                     font-style: normal;
//                     font-weight: 600;
//                     line-height: 100%;
//                 }
//             }

//         }
//     }

//     .bottom-logo {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         flex-direction: column;
//         gap: 20px;
//         margin-top: 51px;

//         h4 {
//             color: #FFF;
//             text-align: center;
//             font-size: 25px;
//             font-style: normal;
//             font-weight: 700;
//             line-height: 120%;
//             text-transform: uppercase;
//         }
//     }
//     .parent-after{
//         display: flex;
//         justify-content: space-between;
//         align-items: flex-start;
//         gap: 60px;
//          .main-content{
//             max-width: 490px;
//             width: 100%;
//             h4{
//                 color: #FFF;
//                 font-size: 40px;
//                 font-style: normal;
//                 font-weight: 700;
//                 line-height: 120%; 
//                 text-transform: uppercase;
//                 text-align: start;
//             }
//             p{
//                 color: #FFF;
//                 font-size: 20px;
//                 font-style: normal;
//                 font-weight: 400;
//                 line-height: 150%; 
//                 text-transform: capitalize;
//                 text-align: start;
//             }
//          }
//        .leaderboard-data{
//         flex: 1;
//        }
//         .leaderboard .parent{
//             gap: 20px;
//         }
//          .leaderboard .parent .single-item{
//             padding: 30px;
//          }
//          .leaderboard .parent .single-item .text h6{
//             font-size: 22px;
//          }
//          .leaderboard .parent .center-main{
//             padding: 0;
//             padding-top: 30px;
//             .text{
//                 padding: 0 30px;
//                 padding-bottom: 30px;
//             }
//          }
//           .leaderboard .parent .single-item .text{
//             margin-top: 37px;
//          }
//           .leaderboard .parent .single-item .text p{
//             margin: 0;
//           }
//          .bottom-logo h4{
//             color: #FFF;
//             text-align: center;
//             font-size: 28px;
//             font-style: normal;
//             font-weight: 700;
//             line-height: 120%; 
//             text-transform: uppercase;
//          }

//     }

// }

.after-banner {
    padding-bottom: 4.17vw; // 80px -> 4.17vw
    padding-top: 8.13vw; // 156px -> 8.13vw


    .leaderboard {
        background-color: transparent;
        padding: 0;
    }

    .leaderboard-data {
        background: #070707;
        padding: 1.04vw; // 20px -> 1.04vw
        display: flex;
        flex-direction: column;
        gap: 0.52vw; // 10px -> 0.52vw
        max-height: 35.73vw; // 686px -> 35.73vw
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 0.26vw; // 5px -> 0.26vw
        }


        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 0.26vw #131313; // 5px -> 0.26vw
            border-radius: 0.52vw; // 10px -> 0.52vw
        }


        &::-webkit-scrollbar-thumb {
            background: #fff;
            border-radius: 0.52vw; // 10px -> 0.52vw
        }


        &::-webkit-scrollbar-thumb:hover {
            background: #808080;
        }

        .single-leader {
            border: 1px solid rgba(255, 255, 255, 0.09);
            background: #070707;
            padding: 0.68vw 1.04vw; // 13px 20px -> 0.68vw 1.04vw
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left-side {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 1.04vw; // 20px -> 1.04vw

                p {
                    color: #FFF;
                    font-size: 0.83vw; // 16px -> 0.83vw
                    font-style: normal;
                    font-weight: 600;
                    line-height: 100%;
                }

                .twice {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 0.52vw; // 10px -> 0.52vw

                    h6 {
                        color: #FFF;
                        font-size: 0.83vw; // 16px -> 0.83vw
                        font-style: normal;
                        font-weight: 600;
                        line-height: 130%;
                        max-width: 15.63vw; // 300px -> 15.63vw
                    }

                    img {
                        flex-shrink: 0;
                    }
                }
            }

            .right-side {
                h6 {
                    color: #FFF;
                    font-size: 0.83vw; // 16px -> 0.83vw
                    font-style: normal;
                    font-weight: 600;
                    line-height: 100%;
                }
            }

        }
    }

    .bottom-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1.04vw; // 20px -> 1.04vw
        margin-top: 2.66vw; // 51px -> 2.66vw

        h4 {
            color: #FFF;
            text-align: center;
            font-size: 1.3vw; // 25px -> 1.3vw
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
        }
    }

    .parent-after {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 3.13vw; // 60px -> 3.13vw

        .main-content {
            max-width: 40.52vw; // 490px -> 25.52vw
            width: 100%;

            h4 {
                color: #FFF;
                font-size: 2.08vw; // 40px -> 2.08vw
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: uppercase;
                text-align: start;
            }

            p {
                color: #FFF;
                font-size: 1.04vw; // 20px -> 1.04vw
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                text-transform: capitalize;
                text-align: start;
            }
        }

        .leaderboard-data {
            flex: 1;
        }

        .leaderboard .parent {
            gap: 1.04vw; // 20px -> 1.04vw
        }

        .leaderboard .parent .single-item {
            padding: 1.56vw; // 30px -> 1.56vw
        }

        .leaderboard .parent .single-item .text h6 {
            font-size: 1.15vw; // 22px -> 1.15vw
        }

        .leaderboard .parent .center-main {
            padding: 0;
            padding-top: 1.56vw; // 30px -> 1.56vw

            .text {
                padding: 0 1.56vw; // 0 30px -> 0 1.56vw
                padding-bottom: 1.56vw; // 30px -> 1.56vw
            }
        }

        .leaderboard .parent .single-item .text {
            margin-top: 1.93vw; // 37px -> 1.93vw
        }

        .leaderboard .parent .single-item .text p {
            margin: 0;
        }

        .bottom-logo h4 {
            color: #FFF;
            text-align: center;
            font-size: 1.46vw; // 28px -> 1.46vw
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
        }
    }
}



@media (max-width:1600px) {
    .after-banner .parent-after {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .after-banner .parent-after .main-content {
        max-width: 100%;
    }

    .after-banner .bottom-logo {
        display: none;
    }

    .after-banner .parent-after .main-content {
        margin: 0;
    }

    .after-banner .parent-after {
        gap: 0;
    }

    .after-banner .parent-after .leaderboard-data {
        flex: auto;
        width: 100%;
    }

    .blockformobile {
        display: flex !important;
    }

    .main-banner {
        padding-top: 135px;
        padding-bottom: 80px;
    }

    .after-banner .parent-after .main-content h4 {
        font-size: 36px;
        text-align: center;
    }

    .after-banner .parent-after .main-content p {
        font-size: 22px;
        text-align: center;
    }

    .leaderboard-data {
        background: #070707;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px !important;
        max-height: calc(100vh - 850px) !important;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 5px;
        }


        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px #131313;
            border-radius: 10px;
        }


        &::-webkit-scrollbar-thumb {
            background: #fff;
            border-radius: 10px;
        }


        &::-webkit-scrollbar-thumb:hover {
            background: #808080;
        }

        .single-leader {
            border: 1px solid rgba(255, 255, 255, 0.09);
            background: #070707;
            padding: 13px 20px !important;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left-side {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 20px !important;

                p {
                    color: #FFF;
                    font-size: 16px !important;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 100%;
                }

                .twice {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px !important;

                    h6 {
                        color: #FFF;
                        font-size: 16px !important;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 130%;
                        max-width: 300px !important;
                    }

                    img {
                        flex-shrink: 0 !important;
                    }
                }
            }

            .right-side {
                h6 {
                    color: #FFF;
                    font-size: 16px !important;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 100%;
                }
            }

        }
    }

    .after-banner .bottom-logo {
        margin-top: 40px;
    }

    .after-banner .bottom-logo h4 {
        font-size: 22px;
    }

    .after-banner .parent-after .leaderboard .parent .single-item .text {
        margin-top: 40px;
    }

    .after-banner .parent-after .leaderboard .parent .single-item .text h6 {
        font-size: 20px;
    }
    .wrapper-afterbanner .custom-container{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .leaderboard-container{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

@media (max-width: 600px) {
    .main-banner .leaderboard .parent {
        gap: 10px;
    }

    .main-banner .leaderboard .parent .single-item {
        padding: 15px;
    }

    .main-banner .leaderboard .parent .single-item .main-img .main-emoji {
        width: 50px;
        height: 50px;
    }

    .main-banner .leaderboard .parent .single-item {
        height: auto;
        min-height: auto;
    }

    .main-banner .leaderboard .parent .single-item .text h6 {
        font-size: 16px;
    }

    .main-banner .leaderboard .parent .single-item .text p {
        font-size: 12px;
    }

    .main-banner .main-content .bottom-twice .single-card {
        padding: 10px;
    }

    .main-banner .main-content .bottom-twice .single-card h6 {
        font-size: 11px;
    }

    .main-banner .main-content .bottom-twice span img {
        width: 20px;
    }

    .main-banner .main-content h4 {
        font-size: 28px;
    }

    .main-banner .main-content p {
        font-size: 16px;
    }

    .main-banner .leaderboard .main-heading h4 {
        font-size: 24px;
    }

    .main-banner .bottom-btn button {
        font-size: 24px;
    }

    .main-banner .main-content .bottom-twice {
        gap: 5px;
    }

    .main-banner .leaderboard .parent .single-item .main-img .common-center-img {
        width: 30px;
    }

    .main-banner .bottom-btn button {
        padding: 25px;
    }

    .main-banner .leaderboard .parent .single-item .text p {
        margin: 0;
    }

    .after-banner .leaderboard-data {
        padding: 10px;
    }

    .after-banner .leaderboard-data .single-leader .left-side {
        gap: 10px;
    }

    .after-banner .leaderboard-data .single-leader .left-side .twice img {
        width: 25px;
        height: 25px;
    }

    .after-banner .leaderboard-data .single-leader .left-side .twice h6 {
        font-size: 12px;
    }

    .after-banner .leaderboard-data .single-leader .right-side h6 {
        font-size: 12px;
    }

    .after-banner .leaderboard-data .single-leader .left-side p {
        font-size: 12px;
    }

    .main-banner .leaderboard .parent .blurryeffect h6 {
        font-size: 14px;
        width: 100%;
    }

    .after-banner .parent-after .main-content h4 {
        font-size: 28px;
        text-align: center;
    }

    .after-banner .parent-after .main-content p {
        font-size: 16px;
        text-align: center;
    }

    .after-banner .parent-after .leaderboard .parent {
        gap: 10px;
    }

    .after-banner .parent-after .leaderboard .parent .single-item {
        padding: 15px;
    }

    .after-banner .parent-after .leaderboard .parent .single-item .text {
        padding: 0;
    }

    .after-banner .parent-after .leaderboard .parent .single-item .text h6 {
        font-size: 16px;
    }

    .after-banner .parent-after .leaderboard .parent .single-item {
        min-height: auto;
    }

    .after-banner {
        padding-top: 135px;
    }

    .leaderboard-data {
        background: #070707;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px !important;
        max-height: 686px !important;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 5px;
        }


        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px #131313;
            border-radius: 10px;
        }


        &::-webkit-scrollbar-thumb {
            background: #fff;
            border-radius: 10px;
        }


        &::-webkit-scrollbar-thumb:hover {
            background: #808080;
        }

        .single-leader {
            border: 1px solid rgba(255, 255, 255, 0.09);
            background: #070707;
            padding: 13px 20px !important;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left-side {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 20px !important;

                p {
                    color: #FFF;
                    font-size: 16px !important;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 100%;
                }

                .twice {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px !important;

                    h6 {
                        color: #FFF;
                        font-size: 16px !important;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 130%;
                        max-width: 300px !important;
                    }

                    img {
                        flex-shrink: 0 !important;
                    }
                }
            }

            .right-side {
                h6 {
                    color: #FFF;
                    font-size: 16px !important;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 100%;
                }
            }

        }
    }

    .after-banner .parent-after .leaderboard .parent .single-item .text {
        margin-top: 30px;
    }
}

.wrapper-afterbanner {
    .btn-launch {
        display: none !important;
    }

    .custom-container {
        max-width: 2560px;
        width: 100%;
        padding-left: 60px;
        padding-right: 60px;
        margin: 0 auto;
    }

    .btn-backhome {
        display: flex !important;
        border: 0.052vw solid #FFF;
        background: #000;
        box-shadow: 0.104vw 0.104vw 0 0 #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        h6 {
            padding: 0.52vw 0.625vw;
            color: #FFF;
            font-size: 0.625vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            border-right: 0.052vw solid #FFF;
            background: #000;
        }

        svg {
            width: 1.72vw;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

@media (max-width:1024px) {
    .btn-backhome {
        border: 1px solid #FFF !important;
        background: #000;
        box-shadow: 2px 2px 0px 0px #FFF !important;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        h6 {
            padding: 10px 12px !important;
            color: #FFF;
            font-size: 12px !important;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            border-right: 1px solid #FFF !important;
            background: #000;
        }

        svg {
            width: 33px !important;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

@media (max-width:991px) {
    .wrapper-afterbanner .btn-backhome {
        display: flex !important;
        border: 1px solid #FFF !important;
        background: #000;
        box-shadow: 2px 2px 0px 0px #FFF !important;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        h6 {
            padding: 10px 12px !important;
            color: #FFF;
            font-size: 12px !important;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            border-right: 1px solid #FFF !important;
            background: #000;
        }

        svg {
            width: 33px !important;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

@media (max-width: 1600px) and (max-height: 1000px){
    .leaderboard-data{
        max-height: 680px !important;
    }
}